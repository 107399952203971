import type { Event, EventOccurrence } from "@/models/event";

export default defineComponent({
  name: "EventDateTime",
  props: {
    event: { type: Object as PropType<Event>, required: true },
    occurrence: { type: Object as PropType<EventOccurrence> },
    startOnly: { type: Boolean, default: false },
  },
  render() {
    const start = this.occurrence?.starts_at || getEventStartTime(this.event);
    const end = this.occurrence?.ends_at || getEventEndTime(this.event);

    return formatTimePeriod({
      start,
      end,
      startOnly: this.startOnly,
      hideStart: this.event.hide_start_time,
      hideEnd: this.event.hide_end_time,
    });
  },
});
